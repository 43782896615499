import React from "react";

const Refund = () => {
  return (
    <div className="text-black lg:p-16 p-4">
      <h1 className="text-3xl font-bold mb-4 text-blue-700">Refund policy
      </h1>
      <h4 className="mb-2"> There will be no refunds.

      </h4>
    </div>
  );
};

export default Refund;
