import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import TermsAndConditions from "./component/TermAndCondition";
import Carousel from "./component/crousal";
import EasySteps from "./component/easyStep";
import FantasyCricket from "./component/exyeDiscription";
import FAQSection from "./component/Faq";
import FAQ from "./component/Faq2";
import Footer from "./component/footor";
import PrivacyandPolicy from "./component/privacyPolicy"
// import StatsSection from "./component/userRating";
import Navbar from "./component/navbar";
import TestimonialCarousel from "./component/playerLove";

// Scroll-to-Top function inside App.js
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the pathname changes
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Directly include ScrollToTop here */}
      <>
        <Navbar />
        <Routes>
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={ <PrivacyandPolicy/>} />
          
          {/* Main app content */}
          <Route
            path="/"
            element={
              <>
                <Carousel />
                <EasySteps />
                <FAQ />
                <FantasyCricket />
                <FAQSection />
                <TestimonialCarousel />
              </>
            }
          />
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
